import { makeStyles } from '@itsa.io/ui';

export default makeStyles(() => ({
	root: {
		display: 'flex',
		width: '100%',
		maxWidth: 500,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},
}));
