import React, { useEffect, useContext } from 'react';
import { navigate } from 'gatsby';
import WalletConnect from 'components/common/WalletConnect';
import useStyles from 'styles/pages/Login';
import { cryptowalletCtx } from '@itsa.io/web3utils';

const LoginPage = () => {
	const classes = useStyles();
	const { connected } = useContext(cryptowalletCtx);

	useEffect(() => {
		if (connected) {
			navigate('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (connected) {
			navigate('/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connected]);

	const walletConnect = (
		<WalletConnect
			classes={{
				root: classes.metamaskRoot,
				title: classes.metamaskTitle,
				titleNetwork: classes.metamaskTitleNetwork,
				description: classes.metamaskDescription,
				networkIconConnected: classes.metamaskIcon,
				addressDescription: classes.metamaskAddressDescription,
				iconBox: classes.metamaskIconBox,
				iconBoxInner: classes.metamaskIconBoxInner,
				select: classes.metamaskSelect,
				selectRoot: classes.metamaskSelectRoot,
			}}
		/>
	);

	return (
		<>
			<div className={classes.root}>{walletConnect}</div>
		</>
	);
};

export default LoginPage;
